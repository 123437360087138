import axios from "axios";
import store from "../setup/redux/Store";
import * as _helpers from "../_metronic/helpers";
import {buildFormData} from "../app/Utilities";

class Model {
    // private apiUrl = 'https://apinsat.smart-sentino.com/api/';
    private apiUrl = process.env.REACT_APP_API_URL;
    
    public axiosApi

    constructor() {
        const headers: any = {
            "Content-type": "application/json"
        }

        const accesToken = store.getState().authReducer.accessToken

        //console.log(accesToken);
        if (accesToken) {
            axios.defaults.headers.Authorization = accesToken
        }

        const cdi = store.getState().appReducer.cdi

        if (cdi) {
            axios.defaults.headers.common['cdi'] = cdi
        }

        this.axiosApi = axios.create({
            baseURL: this.apiUrl,
            headers: headers
        })
        //config


        this.axiosApi.interceptors.request.use((config) => config, (error) => {
            // whatever you want to do with the error
            console.log(error)
            throw error;
        });

        this.axiosApi.interceptors.response.use((response) => response, (error) => {
            // whatever you want to do with the error
            console.log(error)
            throw error;
        });


    }

    get(path: any) {
        return this.axiosApi.get(path);
    }

    withParams(path: any, params: any) {
        if (params) {
            let queryParams = _helpers.serializeQuery(params, null);
            if (queryParams)
                path = path + "?" + queryParams
        }

        return this.axiosApi.get(path);
    }

    post(path: any, data: any) {
        let formData = new FormData();
        // @ts-ignore
        buildFormData(formData, data)
        //Object.keys(data).forEach(key => formData.append(key, data[key]));
        // @ts-ignore
        //console.log([...formData])
        return this.axiosApi.post(path, formData);
    }
}

export default Model
